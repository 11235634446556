import { Link } from "react-router-dom";
import { grayLogo } from "../../assets";
import "./index.css";

function DiplomaPrograms() {
  const programs = [
    {
      title: "Information Technology Professionals Examination Council (ITPEC)",
      secondTitle:
        "Information Technology Professionals Examination Council (ITPEC)",
      description:
        " is an internationally recognized certification body that plays a pivotal role in standardizing IT skills across Asia. ",
      programInfo: "/itpec",
      downloadLink: "",
    },
  ];
  return (
    <>
      <div
        className="flex flex-col gap-5"
        style={{
          backgroundColor: " #162b3e",
        }}>
        <h1
          className="text-white text-3xl mt-10  text-left font-bold"
          style={{ fontWeight: "500", paddingLeft: "135px" }}>
          Discover Our Programs
        </h1>
        {programs.map((program, id) => (
          <div key={id} className="w-full program-container">
            <div className="flex flex-row justify-start gap-5">
              <div>
                <img
                  src={grayLogo}
                  alt="Institude Logo"
                  style={{ height: "143px", width: "192px" }}
                />
              </div>
              <div className="flex flex-col justify-start items-start">
                <h3 className="text-lg font-bold">{program.title}</h3>
                <div className="mt-5 mb-5 flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="73"
                    height="6"
                    viewBox="0 0 73 6"
                    fill="none">
                    <path
                      d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM3 3.5L73 3.49999L73 2.49999L3 2.5L3 3.5Z"
                      fill="white"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="96"
                    height="6"
                    viewBox="0 0 96 6"
                    fill="none">
                    <path
                      d="M90.3333 3C90.3333 4.47276 91.5272 5.66667 93 5.66667C94.4728 5.66667 95.6667 4.47276 95.6667 3C95.6667 1.52724 94.4728 0.333333 93 0.333333C91.5272 0.333333 90.3333 1.52724 90.3333 3ZM0 3.5H93V2.5H0V3.5Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <p
                  className="w-max-[660px] text-left"
                  style={{ lineHeight: "25px", fontSize: "16px" }}>
                  <b>{program.secondTitle}</b> {program.description}
                </p>
              </div>
              <div className="flex flex-col justify-center items-center">
                <Link to={program.programInfo} className="program-button ">
                  <button className="dark-text">
                    <div className="-translate-y-6 -translate-x-6 ">
                      <iframe
                        src="https://lottie.host/embed/8ae2bf93-4d98-4a06-9a26-8e972e68c340/0kSvjq2ciG.json"
                        title="pointer"
                        style={{
                          width: "108px",
                          height: "108px",
                          transform: "rotate(49deg)",
                          zIndex: "2",
                        }}></iframe>{" "}
                    </div>
                    <p className="text-sm -translate-y-[100px] ml-1 dark-text">
                      Program Info
                    </p>
                  </button>
                </Link>
                <Link to={program.downloadLink} className="program-button">
                  <button className="flex flex-row items-center">
                    <iframe
                      src="https://lottie.host/embed/359e5c72-cb08-4b3a-8640-66f610bec12d/zsw9ygRpAU.json"
                      title="download button"
                      style={{ height: "25px", width: "25px" }}></iframe>
                    <p className="text-sm ml-1 dark-text">Download Brochure</p>
                  </button>
                </Link>{" "}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default DiplomaPrograms;
