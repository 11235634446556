import { ProgramQandA } from "../../cards";
import React, { useState } from "react";

function SoftwareDevelopmentQandA() {
  const [openIndex, setOpenIndex] = useState(0); // First question open by default
  const courseDetails = [
    {
      title: "Course Outline",
      details: [
        "𝐌𝐨𝐝𝐮𝐥𝐞 𝟏: 𝐈𝐧𝐭𝐫𝐨𝐝𝐮𝐜𝐭𝐢𝐨𝐧 𝐭𝐨 𝐒𝐨𝐟𝐭𝐰𝐚𝐫𝐞 𝐃𝐞𝐯𝐞𝐥𝐨𝐩𝐦𝐞𝐧𝐭",
        "𝐌𝐨𝐝𝐮𝐥𝐞 𝟐: 𝐒𝐨𝐟𝐭𝐰𝐚𝐫𝐞 𝐄𝐧𝐠𝐢𝐧𝐞𝐞𝐫𝐢𝐧𝐠",
        "𝐌𝐨𝐝𝐮𝐥𝐞 𝟑: 𝐀𝐠𝐢𝐥𝐞 𝐚𝐧𝐝 𝐒𝐜𝐫𝐮𝐦",
        "𝐌𝐨𝐝𝐮𝐥𝐞 𝟒: 𝐏𝐫𝐨𝐣𝐞𝐜𝐭 𝐌𝐚𝐧𝐚𝐠𝐞𝐦𝐞𝐧𝐭",
        "𝐌𝐨𝐝𝐮𝐥𝐞 𝟓: 𝐒𝐨𝐟𝐭𝐰𝐚𝐫𝐞 𝐃𝐞𝐬𝐢𝐠𝐧 𝐚𝐧𝐝 𝐀𝐫𝐜𝐡𝐢𝐭𝐞𝐜𝐭𝐮𝐫𝐞",
        "𝐌𝐨𝐝𝐮𝐥𝐞 𝟔: 𝐂𝐨𝐝𝐢𝐧𝐠 𝐏𝐫𝐚𝐜𝐭𝐢𝐜𝐞𝐬 𝐚𝐧𝐝 𝐓𝐞𝐬𝐭𝐢𝐧𝐠",
        "𝐌𝐨𝐝𝐮𝐥𝐞 𝟕: 𝐃𝐞𝐩𝐥𝐨𝐲𝐦𝐞𝐧𝐭 𝐚𝐧𝐝 𝐌𝐚𝐢𝐧𝐭𝐞𝐧𝐚𝐧𝐜𝐞",
        "𝐌𝐨𝐝𝐮𝐥𝐞 𝟖: 𝐂𝐲𝐛𝐞𝐫𝐬𝐞𝐜𝐮𝐫𝐢𝐭𝐲 𝐢𝐧 𝐒𝐨𝐟𝐭𝐰𝐚𝐫𝐞 𝐃𝐞𝐯𝐞𝐥𝐨𝐩𝐦𝐞𝐧𝐭",
        "𝐌𝐨𝐝𝐮𝐥𝐞 𝟗: 𝐒𝐨𝐟𝐭𝐰𝐚𝐫𝐞 𝐏𝐥𝐚𝐭𝐟𝐨𝐫𝐦𝐬 𝐚𝐧𝐝 𝐅𝐫𝐚𝐦𝐞𝐰𝐨𝐫𝐤𝐬",
      ],
      isCourseOutline: true,
    },
    {
      title: "Entry Requirements",
      details: ["Prefer Basic Computer & IT Knowledge"],
      isCourseOutline: false,
    },
    {
      title: "What you'll learn",
      details: [
        "Gain a solid grasp of cloud computing basics, including what the cloud is, its architecture, and its key components like compute, storage, and networking.",
        "Learn the three main cloud service models: Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS), and when to use each.",
        "Understand the differences between public, private, and hybrid cloud environments, and how to select the appropriate deployment model for different business needs.",
        "Learn how to configure and deploy basic cloud infrastructure, including virtual machines, storage, and networks, on platforms like AWS, Azure, or Google Cloud.",
        "Develop the skills to build, deploy, and manage cloud-based applications, preparing you for further study or real-world cloud computing roles.",
      ],
      isCourseOutline: false,
    },
    // {
    //   title: "Teach Digi",
    //   details: [
    //     "Lorem ipsum dolor sit amet consectetur. Scelerisque facilisi orci cras dignissim tristique et nunc eget pharetra.",
    //     "Tempus laoreet at eget sed. Egestas sollicitudin quam justo velit faucibus mi orci pellentesque neque.",
    //     "Turpis mauris mauris integer consequat id tincidunt. Vitae tortor viverra sapien et euismod.",
    //     "Est tortor et cras quam id gravida nibh sed ultrices. Pharetra ac at metus sem lectus senectus.",
    //     "Ultricies lectus adipiscing at porttitor augue vehicula faucibus venenatis.",
    //     "Est sed viverra volutpat tellus facilisi risus. Phasellus amet neque cursus leo.",
    //     "Tempor dui a at porta sed nisi nec.",
    //     "Pulvinar nunc tincidunt nulla id adipiscing.",
    //   ],
    //   isCourseOutline: false,
    // },
    // {
    //   title: "FAQ",
    //   details: [
    //     "Lorem ipsum dolor sit amet consectetur. Scelerisque facilisi orci cras dignissim tristique et nunc eget pharetra.",
    //     "Tempus laoreet at eget sed. Egestas sollicitudin quam justo velit faucibus mi orci pellentesque neque.",
    //     "Turpis mauris mauris integer consequat id tincidunt. Vitae tortor viverra sapien et euismod.",
    //     "Est tortor et cras quam id gravida nibh sed ultrices. Pharetra ac at metus sem lectus senectus.",
    //     "Ultricies lectus adipiscing at porttitor augue vehicula faucibus venenatis.",
    //     "Est sed viverra volutpat tellus facilisi risus. Phasellus amet neque cursus leo.",
    //     "Tempor dui a at porta sed nisi nec.",
    //     "Pulvinar nunc tincidunt nulla id adipiscing.",
    //   ],
    //   isCourseOutline: false,
    // },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle the index
  };

  return (
    <>
      {courseDetails.map((detail, index) => (
        <ProgramQandA
          key={index}
          question={detail.title}
          answers={detail.details}
          isCourseOutline={detail.isCourseOutline}
          isOpen={openIndex === index} // Pass whether this question is open
          onToggle={() => handleToggle(index)} // Pass the toggle function
        />
      ))}
    </>
  );
}

export default SoftwareDevelopmentQandA;
