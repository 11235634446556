import { IoChevronBack } from "react-icons/io5";
import { openBookIcon, footerBg, recentNewsBg } from "../assets";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function SelectedArticle({ newsData }) {
  const { index } = useParams(); // Get the article index from the URL
  const articleIndex = parseInt(index); // Convert the index to an integer
  const article = newsData[articleIndex]; // Get the article directly by index

  if (!article) {
    return <div>Article not found!</div>;
  }

  // Replace double spaces with line breaks
  // eslint-disable-next-line
  const formattedDetails = article.details.replace(/  /g, "<br><br>");

  return (
    <>
      <Helmet>
        <title>{article.title}</title>
      </Helmet>
      <div
        style={{
          backgroundImage: `url(${article.backgroundImage})`,
          minWidth: "100vw",
          minHeight: "300px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}>
        <Link to="/news-and-articles">
          <p className="text-left ml-10 pt-5 text-white">
            <IoChevronBack size={25} />
          </p>
        </Link>
        <h1
          className="text-center text-5xl text-white items-center mt-24  text-bold"
          style={{ textShadow: "0px 4px 4px rgba(0, 166, 254, 0.25)" }}>
          {" "}
        </h1>
      </div>
      <div className="flex flex-row justify-center items-start mb-10 gap-10 mt-10">
        <div style={{ maxWidth: "867px" }}>
          <h1
            className="text-left text-3xl tracking-wide mb-7"
            style={{ lineHeight: "45px" }}>
            {article.title}
          </h1>
          <p
            className="text-justify"
            dangerouslySetInnerHTML={{ __html: formattedDetails }}></p>
        </div>
        <div
          className="flex pt-5 pb-5 flex-col justify-between "
          style={{
            backgroundImage: `url("${recentNewsBg}")`,
            backgroundPosition: "center",
            backgroundSize: "110%",
            backgroundRepeat: "no-repeat",
            minHeight: "400px",
            minWidth: "269px",
            maxWidth: "270px",
          }}>
          <div
            className="w-full text-black h-11 font-bold items-center pt-1 flex justify-evenly"
            style={{
              backgroundImage: `url("${footerBg}")`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}>
            Recent Posts
          </div>

          <div>
            <Link to="/news-and-articles/news/0">
              <div className="flex gap-4 flex-row mb-8">
                <img
                  src={openBookIcon}
                  alt="opened book icon"
                  style={{ width: "20px", height: "20px", paddingLeft: "4px" }}
                />
                <p className="text-white text-sm text-justify pr-2">
                  OIT ကို ဘာကြောင့် ရွေးချယ်သင့်တာလဲ
                </p>
              </div>{" "}
            </Link>{" "}
            <Link to="/news-and-articles/news/1">
              <div className="flex gap-4 flex-row mb-8">
                <img
                  src={openBookIcon}
                  alt="opened book icon"
                  style={{ width: "20px", height: "20px", paddingLeft: "4px" }}
                />
                <p className="text-white text-sm text-left pr-2">
                  Digital နည်းပညာကို တွင်ကျယ်စွာ အသုံးပြုလာတဲ့ခေတ်မှာ
                  မည်သည့်စီးပွားရေးလုပ်ငန်းအတွက်မဆို အသုံးဝင်လာမယ့် IT
                  ပညာရပ်များ
                </p>
              </div>
            </Link>
            <Link to="/news-and-articles/news/2">
              <div className="flex gap-4 flex-row mb-8">
                <img
                  src={openBookIcon}
                  alt="opened book icon"
                  style={{ width: "20px", height: "20px", paddingLeft: "4px" }}
                />
                <p className="text-white text-sm text-left pr-2">
                  ပြည်တွင်းပြည်ပ အာမခံချက်ရှိတဲ့ IT Professional
                  တစ်ယောက်ဖြစ်ဖို့ အိပ်မက်တွေကို OITနဲ့အတူ အကောင်အထည်ဖော်လိုက်ပါ
                </p>
              </div>
            </Link>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectedArticle;
