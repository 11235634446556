import React from "react";
import { FaCircleChevronDown, FaCircleChevronUp } from "react-icons/fa6";

function ProgramQandA({
  question,
  answers,
  isCourseOutline,
  isOpen,
  onToggle,
}) {
  return (
    <div
      className={`light-blue-shadow border-2 w-10/12 dark-text border-blue-950 ${
        isOpen && "border-l-8"
      } cursor-pointer rounded-md`}
      style={{ padding: "19px 31px", margin: "10px 135px" }}
      onClick={onToggle}>
      <div className="flex flex-row justify-between">
        <h1 className="text-base font-semibold">{question}</h1>
        <p>
          {isOpen ? (
            <FaCircleChevronUp size={25} />
          ) : (
            <FaCircleChevronDown size={25} />
          )}
        </p>
      </div>
      {isOpen && (
        <ul className="pb-5 text-left">
          {answers.map((answer, id) => (
            <li
              key={id}
              className={`dark-text text-xs mt-4 ${
                isCourseOutline ? `font-medium` : `font-normal`
              }`}>
              {answer}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ProgramQandA;
