import { Helmet } from "react-helmet";
import { ItpecBody, ItpecHeading, ItpecQandA } from "../components";

function Itpec() {
  return (
    <>
      <Helmet>
        <title>Itpec | Origin Institude of Technology</title>
      </Helmet>
      <ItpecHeading />
      <ItpecBody />
      <ItpecQandA />
    </>
  );
}

export default Itpec;
